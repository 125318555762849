import { Box, HStack, useColorModeValue } from "@chakra-ui/react";
import { EditorProvider } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import { KnowledgeBaseArticle } from "../../../../types/KnowledgeBaseArticle";
import { TextInput } from "../../../../components/design_library";
import { MenuBar } from "../../../../components/texteditor/MenuBar";
import Link from "@tiptap/extension-link";
import React from "react";
import Youtube from "@tiptap/extension-youtube";

export const Main = ({
  selectedArticle,
  setArticle,
}: {
  selectedArticle: any;
  setArticle: (article: KnowledgeBaseArticle) => void;
}) => {
  const extensions = [
    StarterKit.configure({
      heading: {
        levels: [1, 2, 3, 4, 5, 6],
      },
    }),
    Image.configure({
      inline: true,
      allowBase64: true,
    }),
    Link.configure({
      openOnClick: false,
      autolink: true,
      defaultProtocol: "https",
    }),
    Youtube.configure({
      controls: false,
      nocookie: true,
    }),
  ];

  if (!selectedArticle) {
    return (
      <Box
        as="main"
        p="4"
        color={useColorModeValue("gray.700", "white")}
        textAlign={"center"}
        h="100%"
        alignItems={"center"}
      >
        No article selected
      </Box>
    );
  }

  return (
    <Box as="main" h="100%">
      <EditorProvider
        slotBefore={
          <Box className="menubarfixed">
            <MenuBar />
            {selectedArticle && (
              <HStack>
                <TextInput
                  className="removeoutline"
                  type={"text"}
                  bg={"var(--bg)"}
                  fontSize={"36px"}
                  h="auto"
                  placeholder="Enter title"
                  onChange={(e: any) => {
                    setArticle({
                      ...selectedArticle,
                      slug: e.target.value.toLowerCase().replace(/ /g, "-"),
                      title: e.target.value,
                    });
                  }}
                  value={selectedArticle?.title}
                />
              </HStack>
            )}
          </Box>
        }
        extensions={extensions}
        content={selectedArticle?.content}
        onUpdate={(e) => {
          setArticle({
            ...selectedArticle,
            content: e.editor.getHTML(),
          });
        }}
        editorProps={{
          attributes: {
            class: "prose",
          },
        }}
      ></EditorProvider>
    </Box>
  );
};
