import React from "react";
import { useAtomValue } from "jotai";
import {
  Flex,
  ListIcon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { Config } from "./config/Config";
import { Instruments } from "./instruments/Instruments";
import { AppStateAtom } from "../../store";
import Users from "./users/Users";
import Files from "./files/Files";
import WebsiteSettings from "./settings/WebsiteSettings";
import TradeConfirmation from "./tradeconfirmation/TradeConfirmation";
import KnowledgeBaseAdmin from "./knowledgebase/KnowledgeBaseAdmin";
import Images from "./images/Images";
import BlogAdmin from "./blogs/BlogAdmin";
import { EmailIcon, SettingsIcon } from "@chakra-ui/icons";
import { FaFileUpload, FaGuitar, FaQq, FaQuestion } from "react-icons/fa";
import { RiListIndefinite, RiUserSearchLine } from "react-icons/ri";
import { MdLocalPostOffice } from "react-icons/md";
import { BiNews, BiPhotoAlbum } from "react-icons/bi";

export const Admin = () => {
  const appState = useAtomValue(AppStateAtom);

  if (!appState.authed || appState.user?.role !== "admin") {
    return null;
  }

  return (
    <Flex h="full" w="full" p={2}>
      <Tabs h="full" w="full" isLazy={true}>
        <TabList>
          <Tab>
            <FaGuitar />
            <Text ml="6px">Instruments</Text>
          </Tab>
          <Tab>
            <RiListIndefinite />
            <Text ml="6px">Config</Text>
          </Tab>
          <Tab>
            <RiUserSearchLine />
            <Text ml="6px">Users</Text>
          </Tab>
          <Tab>
            <FaFileUpload />
            <Text ml="6px">Uploads</Text>
          </Tab>
          <Tab>
            <SettingsIcon mr="6px" />
            Settings
          </Tab>
          <Tab>
            <EmailIcon />
            <Text ml="6px">Trade Confirmations</Text>
          </Tab>
          <Tab>
            <FaQuestion />
            <Text ml="6px">FAQ</Text>
          </Tab>
          <Tab>
            <BiNews />
            <Text ml="6px">Blog</Text>
          </Tab>
          <Tab>
            <BiPhotoAlbum />
            <Text ml="6px">Images</Text>
          </Tab>
        </TabList>

        <TabPanels h="calc(100% - 35px)">
          <TabPanel h="full" w="full" p="0">
            <Instruments />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <Config />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <Users />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <Files />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <WebsiteSettings />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <TradeConfirmation />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <KnowledgeBaseAdmin />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <BlogAdmin />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <Images />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
