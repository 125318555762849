import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Text,
  Divider,
  Heading,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
  HStack,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import NoteEditor from "../texteditor/NoteEditor";
import CalendarNoteEditor from "./CalendarNoteEditor";
import convertSymbolToReadable from "../grid/utils/convertSymbolToReadable";
import { useInstruments } from "../../hooks";
import currencyFormatter from "currency-formatter";

export function CalendarNotesModal({
  isOpen,
  onClose,
  date,
  note,
  expiries,
  trades,
}: {
  isOpen: boolean;
  onClose: () => void;
  date: Date;
  note: {
    content: string;
    date: Date;
  };
  expiries: any;
  trades: any;
}) {
  return (
    <Modal isOpen={isOpen} size="5xl" onClose={() => onClose()}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent bg={"var(--dark-gray)"}>
        <ModalHeader>{moment(date).format("Do MMMM YYYY")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="40px">
          <Box as="main" h="100%">
            <CalendarNoteEditor note={note} menuBarEnabled={true} />

            {expiries.length > 0 && (
              <>
                <Heading mt="40px" size="sm">
                  Upcoming expiries on open positions
                </Heading>
                <OptionsTable options={expiries} />
              </>
            )}

            {trades.length > 0 && (
              <>
                <Heading mt="40px" size="sm">
                  Trades for this day
                </Heading>
                <TradeList trades={trades} />
              </>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const OptionsTable = ({ options }: { options: any[] }) => {
  return (
    <Box w="100%" p={4} borderRadius="lg" boxShadow="sm">
      <Table variant="simple" size="md">
        <Thead>
          <Tr>
            <Th>Symbol</Th>
            <Th>Exchange</Th>
            <Th>Type</Th>
            <Th>Put/Call</Th>
            <Th>Strike</Th>
            <Th>Expiry</Th>
            <Th>Quantity</Th>
          </Tr>
        </Thead>
        <Tbody>
          {options.map((option, index) => (
            <Tr key={index}>
              <Td>{convertSymbolToReadable(option.symbol)}</Td>
              <Td>{option.exchange}</Td>
              <Td>{option.instrumentType}</Td>
              <Td>{option.putCall}</Td>
              <Td>{option.strike}</Td>
              <Td>{new Date(option.expiry).toLocaleDateString()}</Td>
              <Td
                color={option.quantity < 0 ? "red.500" : "green.500"}
                fontWeight="bold"
              >
                {option.quantity}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

const TradeList: React.FC<any> = ({ trades }) => {
  const instruments = useInstruments();
  return (
    <Box maxW="100%" p={4}>
      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>Symbol</Th>
              <Th>Trade Date</Th>
              <Th>Buy/Sell</Th>
              <Th>Quantity</Th>
              <Th>Price</Th>
              <Th>Gross Proceeds</Th>
              <Th>Net Proceeds</Th>
              <Th>Commission</Th>
              <Th>Fees</Th>
              <Th>Trade Type</Th>
            </Tr>
          </Thead>
          <Tbody>
            {trades.map((trade) => {
              const ins = instruments.instruments.find(
                (x) => x.id === trade.instrumentId
              );

              return (
                <Tr key={trade.id}>
                  <Td>{ins?.symbol && convertSymbolToReadable(ins.symbol)}</Td>
                  <Td>{new Date(trade.tradeDate).toLocaleDateString()}</Td>
                  <Td>{trade.buySell}</Td>
                  <Td>{trade.quantity}</Td>
                  <Td>
                    {currencyFormatter.format(trade.price, { code: "USD" })}
                  </Td>
                  <Td>
                    {currencyFormatter.format(trade.grossProceeds, {
                      code: "USD",
                    })}
                  </Td>
                  <Td>
                    {currencyFormatter.format(trade.netProceeds, {
                      code: "USD",
                    })}
                  </Td>
                  <Td>
                    {currencyFormatter.format(trade.commission, {
                      code: "USD",
                    })}
                  </Td>
                  <Td>
                    {currencyFormatter.format(trade.fees, { code: "USD" })}
                  </Td>
                  <Td>{trade.tradeType}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
