import React from "react";
import { Box, Divider, Flex, HStack, Text } from "@chakra-ui/react";

import mixpanel from "mixpanel-browser";
import {
  RiBarChartGroupedFill,
  RiCircleFill,
  RiFilterOffLine,
  RiStockFill,
} from "react-icons/ri";

import { KnowledgeBaseHelperTopics, ToolbarFeatures } from "../../types";
import { useFilters } from "../../hooks/useFilters";
import { ToolbarDropDown } from "../design_library/toolbar/ToolbarDropDown";
import { usePositionController } from "../../features/positions/usePositionController";
import KnowledgeBaseHelper from "../../features/knowledgebase/KnowledgeBaseHelper";
import PricingQueuePoll from "../pricing/PricingQueuePoll";
import {
  LeftArrowIcon,
  RightArrowIcon,
  SecondaryButton,
} from "../design_library";
import { FaArrowRight, FaTags } from "react-icons/fa";
import CustomSwitch from "../design_library/toolbar/Switch";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import {
  BiArrowToRight,
  BiBoltCircle,
  BiBookAlt,
  BiPaperPlane,
  BiPlanet,
} from "react-icons/bi";
import { MdArrowRight, MdBook } from "react-icons/md";
import InsightsManager from "../../features/dashboard/insights/InsightsManager";
import Uploads from "../../features/uploads/Uploads";

export const Toolbar = ({
  enabledOptions,
  helpKey,
  positionsController,
  transactionsController,
}: {
  enabledOptions?: ToolbarFeatures[];
  helpKey?: KnowledgeBaseHelperTopics;
  positionsController?: any;
  transactionsController?: any;
}) => {
  const filtersHook = useFilters();

  return (
    <Flex className="grid-toolbar-hook" m="1">
      <HStack
        className="grid-toolbar-select"
        // mr="4"
        wrap={"wrap"}
        maxW="100vw"
        w="100%"
      >
        {enabledOptions?.includes(ToolbarFeatures.UNDERLYING) && (
          <ToolbarDropDown
            value={
              filtersHook.selectedUnderlying
                ? {
                    label: filtersHook.selectedUnderlying,
                    value: filtersHook.selectedUnderlying,
                  }
                : {
                    label: (
                      <HStack color="white !important">
                        <RiStockFill fill="var(--accent)" />
                        <Text
                          display={{
                            base: "none",
                            md: "block",
                          }}
                        >
                          Underlying ({filtersHook.underlyings.length - 1})
                        </Text>
                      </HStack>
                    ),
                    value: "All",
                  }
            }
            className="tool-bar-select"
            placeholder={
              <HStack color="white !important">
                <RiStockFill fill="var(--accent)" />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  Underlying ({filtersHook.underlyings.length - 1})
                </Text>
              </HStack>
            }
            options={filtersHook.underlyings.map((underlying) => ({
              value: underlying,
              label: underlying,
            }))}
            onChange={(event: any) => {
              mixpanel.track("Underlying Filter Changed", {
                underlying: event.value,
              });
              filtersHook.setSelectedUnderlying(
                event.value === "All" ? undefined : event.value
              );
            }}
          />
        )}

        {enabledOptions?.includes(ToolbarFeatures.STATUS) && (
          <ToolbarDropDown
            className="tool-bar-select"
            placeholder={
              <HStack color="white !important">
                <RiCircleFill fill="var(--accent)" />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  Status
                </Text>
              </HStack>
            }
            options={[
              { value: "all", label: "All positions" },
              { value: "active", label: "Active" },
              { value: "open", label: "Open positions only" },
              { value: "closed", label: "Closed positions only" },
            ]}
            onChange={(event: any) => {
              mixpanel.track("Position View Changed", {
                positionView: event.value,
              });

              filtersHook.setPositionStatus(event.value);
            }}
          />
        )}

        {enabledOptions?.includes(ToolbarFeatures.GROUPING) &&
          positionsController && (
            <ToolbarDropDown
              className="tool-bar-select"
              placeholder={
                <HStack color="white !important">
                  <RiBarChartGroupedFill fill="var(--accent)" />
                  <Text
                    display={{
                      base: "none",
                      md: "block",
                    }}
                  >
                    Grouping
                  </Text>
                </HStack>
              }
              value={
                positionsController.grouping !== "B/U/P"
                  ? {
                      label: positionsController.grouping,
                      value: positionsController.grouping,
                    }
                  : {
                      label: (
                        <HStack color="white !important">
                          <RiBarChartGroupedFill fill="var(--accent)" />
                          <Text
                            display={{
                              base: "none",
                              md: "block",
                            }}
                          >
                            Grouping
                          </Text>
                        </HStack>
                      ),
                      value: "None",
                    }
              }
              options={[
                { value: "B/U/P", label: "Book -> Underlying -> Positions" },
                { value: "U/B/P", label: "Underlying -> Book -> Positions" },
                { value: "B/P/U", label: "Book -> Positions -> Underlying" },
                { value: "None", label: "No grouping" },
              ]}
              onChange={(event: any) => {
                positionsController.selectGrouping(event.value);
              }}
            />
          )}

        {enabledOptions?.includes(ToolbarFeatures.TAGS) && (
          <ToolbarDropDown
            className="tool-bar-select"
            placeholder={
              <HStack color="white !important">
                <FaTags fill="var(--accent)" />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  Tags
                </Text>
              </HStack>
            }
            options={filtersHook.tags}
            value={
              filtersHook.selectedTag && filtersHook.selectedTag !== "All"
                ? {
                    label: filtersHook.selectedTag,
                    value: filtersHook.selectedTag,
                  }
                : {
                    label: (
                      <HStack color="white !important">
                        <FaTags fill="var(--accent)" />
                        <Text
                          display={{
                            base: "none",
                            md: "block",
                          }}
                        >
                          Tags
                        </Text>
                      </HStack>
                    ),
                    value: "All",
                  }
            }
            onChange={(event: any) => {
              filtersHook.setSelectedTag(event.value);
            }}
          />
        )}

        {enabledOptions?.includes(ToolbarFeatures.POSITION_GROUPING) && (
          <ToolbarDropDown
            className="tool-bar-select"
            placeholder={
              <HStack color="white !important">
                <BiArrowToRight fill="var(--accent)" />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  Position Groups
                </Text>
              </HStack>
            }
            options={filtersHook.positionGroups.map((group) => ({
              value: group,
              label: group,
            }))}
            value={
              filtersHook.selectedPositionGroup &&
              filtersHook.selectedPositionGroup !== "All"
                ? {
                    label: filtersHook.selectedPositionGroup,
                    value: filtersHook.selectedPositionGroup,
                  }
                : {
                    label: (
                      <HStack color="white !important">
                        <BiArrowToRight fill="var(--accent)" />
                        <Text
                          display={{
                            base: "none",
                            md: "block",
                          }}
                        >
                          Position Groups
                        </Text>
                      </HStack>
                    ),
                    value: "All",
                  }
            }
            onChange={(event: any) => {
              filtersHook?.setSelectedPositionGroup(event.value);
            }}
          />
        )}

        {/*  */}
        {enabledOptions?.includes(ToolbarFeatures.STRATEGY) && (
          <ToolbarDropDown
            className="tool-bar-select"
            placeholder={
              <HStack color="white !important">
                <BiPaperPlane fill="var(--accent)" />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  Strategy
                </Text>
              </HStack>
            }
            options={filtersHook.tradedStrategies.map((strategy) => ({
              value: strategy,
              label: strategy,
            }))}
            value={
              filtersHook.selectedStrategy &&
              filtersHook.selectedStrategy !== "All"
                ? {
                    label: filtersHook.selectedStrategy,
                    value: filtersHook.selectedStrategy,
                  }
                : {
                    label: (
                      <HStack color="white !important">
                        <BiPaperPlane fill="var(--accent)" />

                        <Text
                          display={{
                            base: "none",
                            md: "block",
                          }}
                        >
                          Strategy
                        </Text>
                      </HStack>
                    ),
                    value: "All",
                  }
            }
            onChange={(event: any) => {
              filtersHook?.setSelectedStrategy(event.value);
            }}
          />
        )}
        {/*  */}

        {enabledOptions?.includes(ToolbarFeatures.BOOKS) && (
          <ToolbarDropDown
            className="tool-bar-select"
            placeholder={
              <HStack color="white !important">
                <MdBook fill="var(--accent)" />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  Books
                </Text>
              </HStack>
            }
            options={filtersHook.books.map((book) => ({
              value: book,
              label: book,
            }))}
            value={
              filtersHook.selectedBook && filtersHook.selectedBook !== "All"
                ? {
                    label: filtersHook.selectedBook,
                    value: filtersHook.selectedBook,
                  }
                : {
                    label: (
                      <HStack color="white !important">
                        <MdBook fill="var(--accent)" />
                        <Text
                          display={{
                            base: "none",
                            md: "block",
                          }}
                        >
                          Books
                        </Text>
                      </HStack>
                    ),
                    value: "All",
                  }
            }
            onChange={(event: any) => {
              filtersHook?.setSelectedBook(event.value);
            }}
          />
        )}

        {enabledOptions?.includes(
          ToolbarFeatures.TOGGLE_UNBOOKED_AND_NO_POS_GROUP
        ) && (
          <>
            <CustomSwitch
              id="isChecked"
              isChecked={transactionsController.showUnBookedTrades}
              onChange={(e: any) => {
                transactionsController.setShowUnBookedTrades(
                  !transactionsController.showUnBookedTrades
                );
              }}
              mr={2}
              className="custom-switch-with-elipsis"
            >
              Unbooked ({transactionsController.tradesWithNoBook})
            </CustomSwitch>

            <CustomSwitch
              id="isChecked"
              className="custom-switch-with-elipsis"
              isChecked={transactionsController.showNoPositionGrouping}
              onChange={(e: any) => {
                transactionsController.setShowNoPositionGrouping(
                  !transactionsController.showNoPositionGrouping
                );
              }}
              mr={2}
            >
              No position group (
              {transactionsController.tradesWithNoPositionGrouping})
            </CustomSwitch>
          </>
        )}

        {/* {enabledOptions?.includes(ToolbarFeatures.DATE_RANGE) && (
          <HStack>
            <DatePickerC
              placeholderText={"Date from"}
              setValue={filtersHook.setFromDate}
              value={filtersHook.fromDate}
            />
            <FaArrowRight />
            <DatePickerC
              placeholderText={"Date to"}
              setValue={filtersHook.setToDate}
              value={filtersHook.toDate}
            />
          </HStack>
        )} */}

        {enabledOptions?.includes(ToolbarFeatures.CLEAR) && (
          <SecondaryButton
            bg="var(--bg)"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to reset the table layout?"
                )
              ) {
                Object.keys(localStorage).forEach((key) => {
                  if (key.includes("ColumnState")) {
                    localStorage.removeItem(key);
                  }
                });

                window.location.reload();
              }
            }}
          >
            <RiFilterOffLine fontSize={"20px"} color={"var(--accent)"} />
          </SecondaryButton>
        )}

        {enabledOptions?.includes(ToolbarFeatures.INSIGHTS) && (
          <InsightsManager />
        )}

        {enabledOptions?.includes(ToolbarFeatures.PRICING_QUEUE) && (
          <PricingQueuePoll />
        )}

        {enabledOptions?.includes(ToolbarFeatures.HELP) && (
          <KnowledgeBaseHelper topic={helpKey} />
        )}
      </HStack>

      <Box
        display={{
          base: "none",
          lg: "block",
        }}
      >
        <Uploads />
      </Box>
    </Flex>
  );
};

const DatePickerC = (props: {
  placeholderText: string;
  value?: Date;
  setValue?: (date: Date) => void;
}) => {
  let value = props.value;
  let setValue = props.setValue ? props.setValue : () => {};
  return (
    <ReactDatePicker
      id={"datePicker-" + props.placeholderText}
      preventOpenOnFocus={true}
      className={"react-datepicker react-datepicker-toolbar"}
      renderCustomHeader={({
        date,
        monthDate,
        customHeaderCount,
        decreaseMonth,
        increaseMonth,
      }) => {
        return (
          <div>
            <button
              aria-label="Previous Month"
              className={
                "react-datepicker__navigation react-datepicker__navigation--previous"
              }
              onClick={decreaseMonth}
            >
              <LeftArrowIcon
                className={"react-datepicker-icon-left"}
                stroke="var(--white)"
              />
            </button>
            <span className="react-datepicker__current-month">
              {moment(date).format("MMMM YYYY")}
            </span>
            <button
              aria-label="Next Month"
              className={
                "react-datepicker__navigation react-datepicker__navigation--next"
              }
              onClick={increaseMonth}
            >
              <RightArrowIcon
                className={"react-datepicker-icon-right"}
                stroke="var(--white)"
              />
            </button>
          </div>
        );
      }}
      selected={value}
      onChange={(date) => setValue(date)}
      dateFormat="MM/dd/yyyy"
      placeholderText={props.placeholderText}
    />
  );
};
