import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { MobileDrawer } from "./MobileDrawer";
import { useNavigate } from "react-router";
import { SubscriptionStatus } from "../../components/subscription/SubscriptionStatus";
import Uploads from "../uploads/Uploads";
import AccountManager from "../../components/account/Account";
import { MdSettings } from "react-icons/md";
import { useAtomValue } from "jotai";
import { AppStateAtom } from "../../store";
import { DiscordHoverInvite } from "../../components/misc/DiscordHoverInvite";
import React from "react";
import { RiAdminLine } from "react-icons/ri";
import DiscordInvite from "../../components/misc/DiscordInvite";

export const Navbar = () => {
  const navigate = useNavigate();
  const appState = useAtomValue(AppStateAtom);

  return (
    <Box borderBottomWidth="1px" className="navbar-hook">
      <Box py="4" px="4">
        <HStack
          justify="space-between"
          spacing="8"
          className="navbar-hook-child"
        >
          <HStack spacing="1" className="navbar-hook-container">
            <HStack spacing="1">
              <MobileDrawer />
              <Image
                className="navbar-hook-logo"
                src="https://cdn.wealthbee.io/main/logos/wealthbee-logo-with-text.svg"
                alt="WealthBee"
                maxW={"200px"}
                display={{
                  base: "none",
                  sm: "block",
                }}
                onClick={() => {
                  navigate("/app/dashboard");
                }}
                pb="1"
                cursor="pointer"
              />
            </HStack>
            <ButtonGroup
              size="lg"
              variant="text"
              colorScheme="gray"
              spacing="1"
              display={{ base: "none", lg: "flex" }}
            >
              <Button
                className={
                  window.location.pathname === "/app/dashboard"
                    ? "navbar-hook-menu-item-base navbar-hook-menu-item-selected"
                    : "navbar-hook-menu-item navbar-hook-menu-item-base"
                }
                onClick={() => {
                  navigate("/app/dashboard");
                }}
                color={
                  window.location.pathname === "/app/dashboard"
                    ? "var(--accent)"
                    : ""
                }
              >
                Dashboard
              </Button>
              <Button
                className={
                  window.location.pathname === "/app/positions"
                    ? "navbar-hook-menu-item-base navbar-hook-menu-item-selected"
                    : "navbar-hook-menu-item navbar-hook-menu-item-base"
                }
                onClick={() => {
                  navigate("/app/positions");
                }}
                color={
                  window.location.pathname === "/app/positions"
                    ? "var(--accent)"
                    : ""
                }
              >
                Positions
              </Button>

              <Button
                className={
                  window.location.pathname === "/app/transactions"
                    ? "navbar-hook-menu-item-base navbar-hook-menu-item-selected"
                    : "navbar-hook-menu-item navbar-hook-menu-item-base"
                }
                onClick={() => {
                  navigate("/app/transactions");
                }}
                color={
                  window.location.pathname === "/app/transactions"
                    ? "var(--accent)"
                    : ""
                }
              >
                Transactions
              </Button>

              <Button
                className={
                  window.location.pathname === "/app/cashflows"
                    ? "navbar-hook-menu-item-base navbar-hook-menu-item-selected"
                    : "navbar-hook-menu-item navbar-hook-menu-item-base"
                }
                onClick={() => {
                  navigate("/app/cashflows");
                }}
                color={
                  window.location.pathname === "/app/cashflows"
                    ? "var(--accent)"
                    : ""
                }
              >
                Cashflows
              </Button>
              <Button
                className={
                  window.location.pathname === "/app/calendar"
                    ? "navbar-hook-menu-item-base navbar-hook-menu-item-selected"
                    : "navbar-hook-menu-item navbar-hook-menu-item-base"
                }
                onClick={() => {
                  navigate("/app/calendar");
                }}
                color={
                  window.location.pathname === "/app/calendar"
                    ? "var(--accent)"
                    : ""
                }
              >
                Calendar
              </Button>
              <Button
                className={
                  window.location.pathname === "/app/prices"
                    ? "navbar-hook-menu-item-base navbar-hook-menu-item-selected"
                    : "navbar-hook-menu-item navbar-hook-menu-item-base"
                }
                onClick={() => {
                  navigate("/app/prices");
                }}
                color={
                  window.location.pathname === "/app/prices"
                    ? "var(--accent)"
                    : ""
                }
              >
                Prices
              </Button>
              {/* Admin menu */}
              {appState.role === "admin" && (
                <Button
                  className={
                    window.location.pathname === "/app/admin"
                      ? "navbar-hook-menu-item-base navbar-hook-menu-item-selected"
                      : "navbar-hook-menu-item navbar-hook-menu-item-base"
                  }
                  onClick={() => {
                    navigate("/app/admin");
                  }}
                  opacity={0.5}
                >
                  <RiAdminLine />
                </Button>
              )}
            </ButtonGroup>
            {/* <Box minW="90px">
              <DiscordInvite />
            </Box> */}
          </HStack>
          <HStack>
            {/* <DiscordHoverInvite /> */}
            <Box display={{ base: "none", lg: "flex" }}>
              <AccountManager />
            </Box>

            <SubscriptionStatus />

            <Box
              display={{
                base: "none",
                lg: "block",
              }}
            >
              <IconButton
                display={{ base: "none", md: "flex" }}
                aria-label="Settings"
                icon={<MdSettings />}
                cursor={"pointer"}
                onClick={() => {
                  navigate("/app/settings");
                }}
                className="navbar-hook-settings"
              />
            </Box>
          </HStack>
        </HStack>
      </Box>
    </Box>
  );
};
