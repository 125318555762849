import { Box, Center, ModalOverlay, Spinner } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "../../components/calendar";
import "./Journal.css";
import { CreateCalendarNote, GetCalendarNotes } from "../../api/CalendarNotes";
import { useAtomValue } from "jotai";
import { AccountAtom, AppStateAtom } from "../../store";
import { NotesModal } from "../notes/Notes";
import { CalendarNotesModal } from "../../components/calendar/CalendarNoteModal";
import { usePositions } from "../../hooks";
const localizer = momentLocalizer(moment);

const CalendarComponent: React.FC = () => {
  const [events, setEvents] = useState<any[]>([]);

  const [dailyPandL, setDailyPandL] = useState<any>({});
  const [month, setMonth] = useState(moment.utc().format("MMM YYYY"));
  const [day, setDay] = useState(moment.utc().format("DD MMM YYYY"));
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState<any[]>([]);
  const [selectedNote, setSelectedNote] = useState<any>(null);
  const [expiries, setExpiries] = useState<any[]>([]);

  const [isOpen, setIsOpen] = useState(false);

  const accountState = useAtomValue(AccountAtom);
  const [expiriesForThisDate, setExpiriesForThisDate] = useState<any[]>([]);
  const [pnlByDate, setPnlByDate] = useState<any[]>([]);
  const [trades, setTrades] = useState<any[]>([]);
  const [tradesForThisDate, setTradesForThisDate] = useState<any[]>([]);

  const positions = usePositions();

  useEffect(() => {
    getNotes(month);
  }, []);

  const getNotes = async (month: string) => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 5000);

    const notes = await GetCalendarNotes(
      month,
      accountState.selectedAccount.id
    );
    setNotes(notes.notes);
    setExpiries(notes.expiries);
    setPnlByDate(notes.pnl);
    setTrades(notes.trades);
    setLoading(false);
  };

  const createNote = async (content: string, date: string) => {
    setLoading(true);
    setExpiriesForThisDate(
      expiries.filter((expiry) => {
        return moment.utc(expiry.expiry).format("YYYY-MM-DD") === date;
      })
    );
    setTradesForThisDate(
      trades.filter((trade) => {
        return moment.utc(trade.tradeDate).format("YYYY-MM-DD") === date;
      })
    );

    const existingNote = notes.find((note) => {
      return (
        moment(note.calendarDateReference).format("YYYY-MM-DD") ===
        moment(date).format("YYYY-MM-DD")
      );
    });
    if (!existingNote) {
      const note = await CreateCalendarNote(
        date,
        content,
        accountState.selectedAccount.id
      );

      setSelectedNote(note.note);
      setIsOpen(true);

      getNotes(month);
    } else {
      setSelectedNote(existingNote);
      setIsOpen(true);
    }
    setLoading(false);
  };

  return (
    <Box p="20px">
      {loading && (
        <Box
          pos="fixed"
          bg="var(--bg)"
          w="100vw"
          h="100vh"
          top="0"
          left="0"
          zIndex={99}
          opacity="0.2"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Spinner opacity="1" size="xl" />
        </Box>
      )}
      <CalendarNotesModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          getNotes(month);
        }}
        note={selectedNote}
        date={selectedNote?.calendarDateReference}
        expiries={expiriesForThisDate}
        trades={tradesForThisDate}
      />
      <Calendar
        setDay={(d) => {
          createNote("", moment(d).format("YYYY-MM-DD"));
        }}
        day={day}
        localizer={localizer}
        events={[]}
        notes={notes}
        expiries={expiries}
        pnlByDate={pnlByDate}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "90vh", width: "100%" }}
        onNavigate={(date: Date) => {
          setMonth(moment.utc(date).format("MMM YYYY"));
          getNotes(moment.utc(date).format("MMM YYYY"));
        }}
        dailyPandL={[]}
      />
    </Box>
  );
};

export default CalendarComponent;
