import React from "react";
import {
  Text,
  Flex,
  HStack,
  Switch,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Box,
  VStack,
} from "@chakra-ui/react";
import {
  GridContainer,
  columnTypes,
  distinctValue,
} from "../../../../components";
import { AgGridReact } from "ag-grid-react";
import { columnDefinitions } from "./columnDefinitions";
import { Instrument } from "../../../../types";
import { useInstrumentPrices } from "./useInstrumentPrices";
import { SecondaryButton } from "../../../../components/design_library";
import { getCurrentInstrumentPrice } from "../../../../api";
import moment from "moment";

interface InstrumentPricesProps {
  instrument?: Instrument;
}

export const InstrumentPrices = (props: InstrumentPricesProps) => {
  const [current, setCurrent] = React.useState(true);
  const controller = useInstrumentPrices(props.instrument);

  return (
    <Flex h="100%" w="100%" mt={2} px={2} py={1} direction="column">
      <Tabs flex={1} h="full" w="full">
        <TabList>
          <Tab>Prices</Tab>
          <Tab>Errors</Tab>
        </TabList>
        <TabPanels h="calc(100% - 35px)">
          <TabPanel h="full">
            <Flex h="full" direction="column">
              <HStack py={2} justifyContent={"space-between"}>
                <Switch
                  id="isChecked"
                  colorScheme="green"
                  isChecked={current}
                  onChange={(e: any) => {
                    setCurrent(!current);
                  }}
                  size="sm"
                  mr={2}
                >
                  {current ? "Current Prices" : "EOD Prices"}
                </Switch>

                <SecondaryButton
                  onClick={async () => {
                    const price = await getCurrentInstrumentPrice(
                      props.instrument?.id as number
                    );
                    alert(price.message);
                  }}
                  bg="var(--chart-blue)"
                  size="xs"
                >
                  Run price update
                </SecondaryButton>
              </HStack>
              <Flex flex={1}>
                <GridContainer>
                  <AgGridReact
                    rowData={controller.prices.filter(
                      (x) =>
                        (current && x.priceType !== "EOD") ||
                        (!current && x.priceType === "EOD")
                    )} // Row Data for Rows
                    rowHeight={31}
                    columnDefs={columnDefinitions} // Column Defs for Columns
                    columnTypes={columnTypes} // Optional - custom column types
                    suppressAggFilteredOnly={false}
                    rowDragManaged={false}
                    suppressAggFuncInHeader={true}
                    onGridReady={(params) => {
                      params.api.autoSizeAllColumns();
                    }}
                    suppressChangeDetection={false}
                    aggFuncs={{
                      distinct: distinctValue,
                    }}
                    onFirstDataRendered={(params) => {
                      params.api.expandAll();
                      params.api.sizeColumnsToFit();
                    }}
                  />
                </GridContainer>
              </Flex>
            </Flex>
          </TabPanel>
          <TabPanel h="100%">
            <Flex
              p={4}
              h="100%"
              w="full"
              border="1px solid"
              borderColor="gray.600"
            >
              <VStack>
                {controller.errors?.map((error) => {
                  return <Text>{error.error}</Text>;
                })}
              </VStack>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
