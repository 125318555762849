import { PositionStateAtom } from "../../store/PositionsStateAtom";
import { useAtomValue } from "jotai";
import { Position } from "../../types/Position";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GetRowIdParams } from "ag-grid-community";
import {
  overlayLoadingTemplate,
  overlayNoRowsTemplate,
} from "../../components/grid/NoRowsToShow";
import {
  getAllChildrenOfGroup,
  getAllChildrenOfOverNode,
} from "../../components";
import { AccountAtom, TransactionsAtom } from "../../store";
import { useTransactions } from "../../hooks/useTransactions";
import mixpanel from "mixpanel-browser";
import { columnDefinitions } from "./columnDefinitions";
import { useNotifications } from "../../hooks/useNotifications";
import { grid } from "@chakra-ui/react";
import { useFilters } from "../../hooks/useFilters";
import { FilterStateAtom } from "../../store/FilterStateAtom";
import { PositionGroupingOptions, PositionStatus } from "../../types";
import { useGridState } from "../../components/grid/useGridState";

interface UsePositionController {
  positions: Position[];
  underlyings: string[];
  getTradeIds: (rowIds: number[]) => string[];
  getRowId: (params: GetRowIdParams) => string;

  loadingOverlayComponent: any;
  loadingOverlayComponentParams: any;
  noRowsOverlayComponent: any;
  noRowsOverlayComponentParams: any;

  onRowDragMove: (params: GetRowIdParams) => void;

  onRowDragEnd: (params: GetRowIdParams) => void;
  selectGrouping: (grouping: PositionGroupingOptions) => void;
  grouping: PositionGroupingOptions | string;
  filterByTag: (tag: string) => void;
  switchingColDef: boolean;
  columnDefs: any;
  selectedTag?: string;
  setSelectedTag: (tag: string | undefined) => void;
  gridApiRef: any;
}

export const usePositionController = (): UsePositionController => {
  const filtersHook = useFilters();
  const transactions = useAtomValue(TransactionsAtom);
  const useTransactionsHook = useTransactions();
  const filters = useAtomValue(FilterStateAtom);
  const accountState = useAtomValue(AccountAtom);
  const notifications = useNotifications();
  const [columnDefs, setColumnDefs] = useState<any>(columnDefinitions);
  const [switchingColDef, setSwitchingColDef] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);
  const positionState = useAtomValue(PositionStateAtom);
  const gridApiRef = useRef<any>(null);
  const [grouping, setGrouping] = useState("B/U/P" as PositionGroupingOptions);

  const reconfigureDefaultColumnDefs = () => {
    columnDefinitions.map((col: any) => {
      if (
        col.field === "realisedPnl" ||
        col.field === "unrealisedPnl" ||
        // col.field === "grossProceeds" ||
        // col.field === "netProceeds" ||
        col.field === "unRealisedPnL" ||
        col.field === "totalPnl" ||
        col.field === "priceData.price" //TEMP!
      ) {
        col.hide = false;
      } else {
        col.hide = true;
      }
    });

    setColumnDefs([...columnDefinitions]);
  };

  useEffect(() => {
    selectGrouping(grouping);
  }, [grouping]);

  const selectGrouping = (groupingValue: PositionGroupingOptions) => {
    reconfigureDefaultColumnDefs();

    mixpanel.track("Position Grouping Changed", {
      groupingValue,
    });

    setGrouping(groupingValue);

    const newColumnDefs = [...columnDefinitions];

    const underlyingColumn = newColumnDefs.find(
      (col: any) => col.field === "underlyingName"
    );
    const bookColumn = newColumnDefs.find((col: any) => col.field === "book");
    const positionNameColumn = newColumnDefs.find(
      (col: any) => col.field === "positionName"
    );

    if (underlyingColumn && bookColumn && positionNameColumn) {
      newColumnDefs.splice(newColumnDefs.indexOf(underlyingColumn), 1);
      newColumnDefs.splice(newColumnDefs.indexOf(bookColumn), 1);
      newColumnDefs.splice(newColumnDefs.indexOf(positionNameColumn), 1);

      setSwitchingColDef(true);

      let reorderedColumns = [];
      switch (groupingValue) {
        case PositionGroupingOptions.UBP:
          reorderedColumns = [underlyingColumn, bookColumn, positionNameColumn];
          break;
        case PositionGroupingOptions.BUP:
          reorderedColumns = [bookColumn, underlyingColumn, positionNameColumn];
          break;
        case PositionGroupingOptions.BPU:
          reorderedColumns = [bookColumn, positionNameColumn, underlyingColumn];
          break;
        case PositionGroupingOptions.NONE:
          newColumnDefs.forEach((col) => {
            col.rowGroup = false;
            col.hide = false;
            col.rowDrag = false;
            if (col.field === "instrument.symbol") {
              col.headerName = "Symbol";
              col.minWidth = 400;
            }
            if (col.field === "lastTradeDate") {
              col.sort = "asc";
            }
          });

          reorderedColumns = newColumnDefs; // No grouping case
          break;
        default:
          reorderedColumns = newColumnDefs; // Fallback
      }

      // Ensure the column definition update only happens once
      setColumnDefs([...reorderedColumns, ...newColumnDefs]);

      setTimeout(() => {
        setSwitchingColDef(false);
      }, 200);
    }
  };

  const filterByTag = (tag: string) => {
    mixpanel.track("Position Tag Filter Changed", {
      tag,
    });
  };

  const positions: Position[] = useMemo(() => {
    switch (filtersHook.positionStatus) {
      case PositionStatus.ACTIVE:
        return positionState.positions.filter((position) =>
          positionState.openPositions.includes(position.id)
        );
      case PositionStatus.OPEN:
        return positionState.positions.filter(
          (position) => position.quantity !== 0
        );
      case PositionStatus.CLOSED:
        return positionState.positions.filter((position) =>
          positionState.closedPositions.includes(position.id)
        );
    }
    return positionState.positions;
  }, [filtersHook.positionStatus, positionState.positions]);

  useEffect(() => {
    reconfigureDefaultColumnDefs();
    if (accountState?.selectedAccount?.id) {
      useTransactionsHook.getTradesAndCashflows(
        accountState?.selectedAccount?.id
      );
    } else {
      window.location.reload();
    }
  }, []);

  const getTradeIds = (rowIds: number[]): string[] => {
    if (positions.length > 0) {
      let tradeIds: number[] = [];
      rowIds.forEach((rowId) => {
        const position = positions.find((x) => x.id === rowId);
        if (position) {
          tradeIds = [...tradeIds, ...position.tradeIds];
        }
      });
      return tradeIds ?? [];
    }
    return [];
  };

  const getRowId = useCallback(function (params: GetRowIdParams) {
    return params.data.id;
  }, []);

  const loadingOverlayComponent = useMemo(() => {
    return overlayLoadingTemplate;
  }, []);

  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "Retrieving positions...",
    };
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return overlayNoRowsTemplate;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () =>
        "No positions found! Please add them using the 'Add assets' button in the top right.",
    };
  }, []);

  const onRowDragMove = (params: GetRowIdParams) => {
    // console.log("onRowDragEnd", params);
  };

  const onRowDragEnd = (params: GetRowIdParams) => {
    mixpanel.track("Position Dragged");

    const nodesData = getAllChildrenOfGroup(params, transactions);
    const overNodesData = getAllChildrenOfOverNode(params, transactions);

    if (params.overNode.group === false) {
      //This is a leaf node
      useTransactionsHook.updateBookAndGroup(
        [...nodesData],
        params.overNode.data.book,
        params.overNode.data.positionName || params.overNode.data.positionGroup,
        accountState?.selectedAccount?.id
      );
    } else if (
      params.overNode.field === "positionGroup" ||
      params.overNode.field === "positionName" ||
      params.overNode.field === "book"
    ) {
      useTransactionsHook.updateBookAndGroup(
        [...nodesData],
        overNodesData[0].book,
        overNodesData[0].positionName ||
          overNodesData[0].positionGroup ||
          params.overNode.aggData.positionName ||
          params.overNode.aggData.positionGroup,
        accountState?.selectedAccount?.id
      );
    }

    // setTimeout(() => {
    //   notifications.clearNotifications("trade-change");
    // }, 5000);

    return;
  };

  const filterPositions = (positions, filters) => {
    let { underlying = "", tag = "" } = filters;

    if (filtersHook.selectedTag === "All") {
      tag = "";
    }

    if (underlying === "All") {
      underlying = "";
    }

    if (filtersHook.selectedPositionGroup === "All") {
      filtersHook.selectedPositionGroup = "";
    }

    if (filtersHook.selectedBook === "All") {
      filtersHook.selectedBook = "";
    }

    if (filtersHook.selectedStrategy === "All") {
      filtersHook.selectedStrategy = "";
    }

    return positions.filter((position: Position) => {
      const underlyingMatch = underlying
        ? position.underlyingSymbol === underlying ||
          position.underlyingName === underlying
        : true;
      const tagsMatch = tag
        ? position.tags.includes(filtersHook.selectedTag)
        : true;
      const positionGroupMatch = filtersHook.selectedPositionGroup
        ? position.positionGroup === filtersHook.selectedPositionGroup
        : true;
      const booksMatch = filtersHook.selectedBook
        ? position.book === filtersHook.selectedBook
        : true;
      const strategiesMatch = filtersHook.selectedStrategy
        ? position.strategy === filtersHook.selectedStrategy
        : true;

      return (
        underlyingMatch &&
        tagsMatch &&
        positionGroupMatch &&
        booksMatch &&
        strategiesMatch
      );
    });
  };

  return {
    positions: filterPositions(positions, {
      underlying: filters.selectedUnderlying,
      tag: filtersHook.selectedTag,
      strategy: filters.selectedStrategy,
      book: filters.selectedBook,
      positionGroup: filters.selectedPositionGroup,
    }),
    selectedTag,
    setSelectedTag,
    getTradeIds,
    getRowId,
    loadingOverlayComponent,
    loadingOverlayComponentParams,
    noRowsOverlayComponent,
    noRowsOverlayComponentParams,
    onRowDragMove,
    onRowDragEnd,
    selectGrouping,
    grouping,
    filterByTag,
    switchingColDef,
    columnDefs,
    gridApiRef,
  };
};
