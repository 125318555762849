import { Box, Center, Heading, Image, Text, VStack } from "@chakra-ui/react";
import NavBar from "../homepage/menu/App";
import Footer from "../homepage/footer/App";
import { Helmet } from "react-helmet";
import React from "react";
import { CustomLink, PrimaryButton } from "../../components/design_library";
import { FeatureList } from "./FeatureList";

const AffiliateMarketing = () => {
  return (
    <Box background={"var(--bg)"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trading Journal Affiliate</title>
        <link rel="canonical" href="https://wealthbee.io/affiliates" />
      </Helmet>
      <NavBar />
      <Center
        maxW="4xl"
        mx="auto"
        minH={"89vh"}
        alignItems={"flex-start"}
        mt="20px"
      >
        <Image
          src="https://cdn.wealthbee.io/main/app/authentication/candles.svg"
          pos="absolute"
          w="full"
          left="50%"
          transform={"translateX(-50%)"}
          justifyContent={"center"}
          zIndex={0}
        />
        <VStack textAlign={"center"} pt="100px" pb="100px">
          <Heading as="h3" size="xs">
            Affiliate
          </Heading>
          <Heading as="h1" size="4xl" mt="20px">
            Earn monthly income with the WealthBee Affiliate Program
          </Heading>
          <Heading as="h2" size="sm" mt="20px" opacity="0.8" fontWeight={400}>
            Earn 30% Lifetime Revenue with the WealthBee Affiliate Program
          </Heading>

          <CustomLink
            href="https://wealthbee.promotekit.com"
            target="_blank"
            mt="20px"
          >
            <PrimaryButton p="20px" px="40px">
              <Text>Join the WealthBee affiliate program</Text>
            </PrimaryButton>
          </CustomLink>

          <Text mt="60px">
            WealthBee is a trading journal that helps traders track their
            investments and grow their wealth. Our platform supports stocks,
            options, and futures. WealthBee was developed in London, UK by
            traders, for traders.
          </Text>
        </VStack>
      </Center>
      <FeatureList />

      <VStack
        maxW="8xl"
        justifyContent={"center"}
        textAlign={"center"}
        py="80px"
      >
        <Heading as="h2" size="xl" mt="20px">
          Getting started
        </Heading>
        <Text maxW="2xl" pt="20px" pb="20px">
          To get started, simply sign up for the WealthBee affiliate program and
          start sharing your unique referral link with your audience. You'll
          earn a 30% lifetime revenue share for every user you refer to us.
        </Text>
        <CustomLink href="https://wealthbee.promotekit.com" target="_blank">
          <PrimaryButton p="20px" px="40px">
            <Text>Join the WealthBee affiliate program</Text>
          </PrimaryButton>
        </CustomLink>
      </VStack>
      <Footer />
    </Box>
  );
};

export default AffiliateMarketing;
