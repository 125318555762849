import { useEffect, useRef, useState } from "react";
import * as api from "../../../../api";
import { createManyTrades } from "../../../../api/Trades";
import { createManyCashFlows } from "../../../../api/Cashflow";
import { useAtom, useAtomValue } from "jotai";
import {
  AccountAtom,
  PricingQueueAtom,
  TransactionsAtom,
} from "../../../../store";
import { useToast } from "@chakra-ui/react";
import { useTransactions } from "../../../../hooks/useTransactions";
import { usePositions } from "../../../../hooks";
import mixpanel from "mixpanel-browser";
import { useTour } from "@reactour/tour";
import { useNotifications } from "../../../../hooks/useNotifications";

interface brokerUploadController {
  search: (value: string) => void;
  reqListOfBrokers: () => void;
  uploadFile: (file: any) => void;
  selectedBroker: string;
  setSelectedBroker: (value: string) => void;
  dataList: any[];
  filteredResults: any[];
  textSearch: string;
  loading: boolean;
  uploading: boolean;
  errorMessage: string;
  trades: any[];
  successfulUpload: boolean;
  setSuccessfulUpload: (value: boolean) => void;
  setErrorMessage: (value: string) => void;
  createBulkTrades: () => void;
  cashflows: any[];
  createBulkCashFlows: () => void;
  setSelectedBook: (value: string) => void;
  selectedBook: string;
  calculateStrategy: boolean;
  setCalculateStrategy: (value: string) => void;
}

export const useBrokerUploadController = (props: any) => {
  const accountState = useAtomValue(AccountAtom);
  const [transactionsState, setTransactionsState] = useAtom(TransactionsAtom);
  const [calculateStrategy, setCalculateStrategy] = useState<any>({
    value: "STRATEGY",
    label: "Strategy field",
  });

  const [successfulUpload, setSuccessfulUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [dataList, setDataList] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedBroker, setSelectedBroker] = useState("");
  const [selectedBook, setSelectedBook] = useState("");
  const [trades, setTrades] = useState([]);
  const [cashflows, setCashFlows] = useState([]);
  const initialised = useRef<boolean>(false);
  const toast = useToast();
  const transactions = useTransactions();
  const positions = usePositions();
  const notifications = useNotifications();

  const [pricingQueueState, setPricingQueueState] = useAtom(PricingQueueAtom);

  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } =
    useTour();

  const uploadFile = (file: any) => {
    mixpanel.track("Upload File Attempt", {
      broker: selectedBroker,
    });

    setErrorMessage("");
    setUploading(true);
    setSuccessfulUpload(false);

    const data = new FormData();
    data.append("file", file);

    api
      .uploadFile(
        data,
        selectedBroker,
        accountState?.selectedAccount?.id,
        selectedBook,
        calculateStrategy.value
      )
      .then((res) => {
        if (res.success === false) {
          mixpanel.track("Upload File Failure", {
            broker: selectedBroker,
            success: res.success,
            message: res.message,
          });
          setErrorMessage(res.message);
          setUploading(false);
          setSuccessfulUpload(false);
          setTrades([]);
          transactions.setTrades([]);
          setCashFlows([]);
          transactions.setCashflows([]);
        } else if (res.success === true) {
          mixpanel.track("Upload File Success", {
            broker: selectedBroker,
            success: res.success,
          });
          setUploading(false);
          setErrorMessage("");
          setSuccessfulUpload(true);
          setTrades(res.trades);
          transactions.setTrades(res.trades);
          setCashFlows(res.cashflows);
          transactions.setCashflows(res.cashflows);

          positions.fetchPositions(accountState?.selectedAccount?.id);

          // notifications.createNotification(
          //   "File uploaded successfully!",
          //   `You have successfully uploaded a file!`
          // );

          // setTimeout(() => {
          //   notifications.clearNotifications();
          // }, 1600);

          if (
            window.location.href.includes("tutorial=true") ||
            window.location.href.includes("result=paid")
          ) {
            setIsOpen(false);
            setTimeout(() => {
              setIsOpen(true);
              setCurrentStep(currentStep + 1);
            }, 800);
          }

          positions.fetchPositions(accountState?.selectedAccount?.id);

          setPricingQueueState({
            active: true,
          });

          props.closeModal();
        } else {
          mixpanel.track("Upload File Failure", {
            broker: selectedBroker,
            success: res.success,
            message: res.message,
          });
          setUploading(false);
          setErrorMessage("");
          setTrades([]);
          transactions.setTrades([]);
          setCashFlows([]);
          transactions.setCashflows([]);
        }
      });
  };

  useEffect(() => {
    if (initialised.current === false) {
      initialised.current = true;
      reqListOfBrokers();
      setSuccessfulUpload(false);
      setSelectedBroker("");
      setErrorMessage("");
      setTrades([]);
    }
  }, []);

  const reqListOfBrokers = () => {
    setLoading(true);

    api.getListOfBrokers().then((res) => {
      setDataList(res.brokers);
      setLoading(false);
    });
  };

  const search = (value: string) => {
    mixpanel.track("Search Broker", {
      search: value,
    });

    setTextSearch(value);
    const filtered = dataList.filter((item: any) => {
      return item.name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredResults(filtered);
  };

  const createBulkTrades = async () => {
    createManyTrades(trades, accountState?.selectedAccount?.id).then((res) => {
      if (res.success === true) {
        mixpanel.track("Bulk Trades Created", {
          account: accountState?.selectedAccount?.id,
        });
        alert("Trades created successfully");
      } else {
        mixpanel.track("Bulk Trades Creation Failed", {
          account: accountState?.selectedAccount?.id,
        });
        alert("Failed to create trades");
      }
    });
  };

  const createBulkCashFlows = async () => {
    createManyCashFlows(cashflows, accountState?.selectedAccount?.id).then(
      (res) => {
        if (res.success === true) {
          mixpanel.track("Bulk Cash Flows Created", {
            account: accountState?.selectedAccount?.id,
          });
          alert("Cash Flows created successfully");
        } else {
          mixpanel.track("Bulk Cash Flows Creation Failed", {
            account: accountState?.selectedAccount?.id,
          });
          alert("Failed to create cash flows");
        }
      }
    );
  };

  return {
    search,
    reqListOfBrokers,
    uploadFile,
    selectedBroker,
    setSuccessfulUpload,
    setSelectedBroker,
    dataList,
    filteredResults,
    textSearch,
    loading,
    uploading,
    errorMessage,
    trades,
    setErrorMessage,
    successfulUpload,
    createBulkTrades,
    createBulkCashFlows,
    cashflows,
    setSelectedBook,
    selectedBook,
    calculateStrategy,
    setCalculateStrategy,
  } as brokerUploadController;
};
