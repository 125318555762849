import { useAtom } from "jotai";
import { AccountAtom } from "../store";
import { Account } from "../types/Account";
import { getAccounts } from "../api";

export interface UseAccount {
  setSelectedAccount: (account: Account) => void;
  retrieveAccounts: () => void;
}

export const useAccount = (): UseAccount => {
  const [accountState, setAccountState] = useAtom(AccountAtom);

  const setSelectedAccount = (account: Account) => {
    localStorage.setItem("selectedAccount", JSON.stringify(account));
    setAccountState((prevState) => {
      return {
        ...prevState,
        selectedAccount: account,
      };
    });
  };

  const retrieveAccounts = async () => {
    const result = await getAccounts();
    if (result && result.success) {
      //check local storage
      const selectedAccount = localStorage.getItem("selectedAccount");

      setAccountState((prevState) => {
        return {
          ...prevState,
          accounts: result.accounts,
          selectedAccount: selectedAccount
            ? JSON.parse(selectedAccount)
            : result.accounts[0],
        };
      });
    } else {
      setTimeout(() => {
        console.log("Retrying accounts...");
        retrieveAccounts();
      }, 1500);
    }
  };

  return {
    setSelectedAccount,
    retrieveAccounts,
  };
};
