import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Box,
  Button,
  ModalCloseButton,
  Select,
} from "@chakra-ui/react";
import React from "react";
import { PrimaryButton, SecondaryButton } from "../design_library";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../store";
import { getTrades, getTradesAsImportCSV } from "../../api/Trades";

export default function ExportAccount() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef(null);
  const [selectedAccount, setSelectedAccount] = React.useState(null);
  const accountState = useAtomValue(AccountAtom);

  const getCSV = async () => {
    const response = await getTradesAsImportCSV(parseInt(selectedAccount));
    const blob = response;
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `trades_by_custodian-${new Date()}.zip`; // File name for the download
    a.click();

    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <Button mt={4} onClick={onOpen} size="sm">
        Export trades
      </Button>
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="var(--bg)">
          <ModalHeader>Export trades</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              placeholder="Select account"
              size="sm"
              onChange={(e) => {
                setSelectedAccount(e.target.value);
              }}
            >
              {accountState.accounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.name}
                </option>
              ))}
            </Select>

            <SecondaryButton
              mt={4}
              size="sm"
              w="full"
              isDisabled={
                selectedAccount !== null && selectedAccount !== ""
                  ? false
                  : true
              }
              onClick={() => {
                getCSV();
              }}
            >
              Export
            </SecondaryButton>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
