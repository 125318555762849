import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import { navigate } from "./utils/constants";
import { Button, HStack } from "@chakra-ui/react";
import {
  LeftArrowIcon,
  RightArrowIcon,
} from "../../components/design_library/Icons";

class Toolbar extends React.Component {
  render() {
    let {
      localizer: { messages },
      label,
    } = this.props;

    return (
      <HStack mb="2" justifyContent={"flex-start"}>
        <span className="rbc-btn-group">
          <Button
            className="journal-button-today"
            onClick={this.navigate.bind(null, navigate.TODAY)}
          >
            {messages.today}
          </Button>
          <Button
            className="journal-button-previous"
            onClick={this.navigate.bind(null, navigate.PREVIOUS)}
          >
            <LeftArrowIcon />
          </Button>
          <Button
            className="journal-button-next"
            onClick={this.navigate.bind(null, navigate.NEXT)}
          >
            <RightArrowIcon />
          </Button>
        </span>

        <span className="rbc-toolbar-label">{label}</span>

        <span className="rbc-btn-group">{this.viewNamesGroup(messages)}</span>
      </HStack>
    );
  }

  navigate = (action) => {
    this.props.onNavigate(action);
  };

  view = (view) => {
    this.props.onView(view);
  };

  viewNamesGroup(messages) {
    let viewNames = this.props.views;
    const view = this.props.view;

    if (viewNames.length > 1) {
      return viewNames.map((name) => (
        <Button
          key={name}
          className={clsx({ "rbc-active": view === name })}
          onClick={this.view.bind(null, name)}
        >
          {messages[name]}
        </Button>
      ));
    }
  }
}

Toolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
};

export default Toolbar;
