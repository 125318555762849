import React, { useState, useEffect, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CustomLink } from "../../components/design_library";
import { useAtomValue } from "jotai";
import { AccountAtom, AppStateAtom } from "../../store";

const AffiliatePopup = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [shouldShowPopup, setShouldShowPopup] = useState(false);
  const appState = useAtomValue(AppStateAtom);

  useEffect(() => {
    if (appState.subscriptionActive === true) {
      const lastShown = localStorage.getItem("affiliatePopupLastShown");
      const now = new Date().getTime();

      const sixHoursInMilliseconds = 6 * 60 * 60 * 1000; // 6 hours
      const fiveDaysInMilliseconds = 5 * 24 * 60 * 60 * 1000; // 5 days

      // If popup has never been shown before, show it after 6 hours
      if (!lastShown) {
        const subscriptionStart = localStorage.getItem("subscriptionStartTime");
        if (!subscriptionStart) {
          // Save the current time as the subscription start time
          localStorage.setItem("subscriptionStartTime", now);
        } else if (now - subscriptionStart > sixHoursInMilliseconds) {
          setShouldShowPopup(true);
          localStorage.setItem("affiliatePopupLastShown", now);
          onOpen();
        }
      }
      // If popup has been shown before, wait 5 days before showing again
      else if (now - lastShown > fiveDaysInMilliseconds) {
        setShouldShowPopup(true);
        localStorage.setItem("affiliatePopupLastShown", now);
        onOpen();
      }
    }
  }, [onOpen]);

  const handleClose = () => {
    setShouldShowPopup(false);
    onClose();
  };

  return (
    <>
      {shouldShowPopup && (
        <AlertDialog isOpen={isOpen} onClose={handleClose} isCentered>
          <AlertDialogOverlay>
            <AlertDialogContent bg="var(--bg)" border="var(--accent) 2px solid">
              <AlertDialogHeader fontSize="2xl" fontWeight="bold">
                Refer a friend!
              </AlertDialogHeader>

              <AlertDialogBody>
                <Text mb={3}>
                  Earn a <strong>30% lifetime commission</strong> on every
                  subscription referred to WealthBee! Sign up for our affiliate
                  program and start earning rewards today.
                </Text>
                <Text>
                  Thats a <strong>$10.50</strong> for every friend who signs up,
                  every month paid into your bank account.
                </Text>
              </AlertDialogBody>

              <AlertDialogFooter justifyContent={"space-between"}>
                <VStack w="full">
                  <CustomLink
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    href="https://wealthbee.promotekit.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    background="var(--accent-dark)"
                    px="16px"
                    h="40px"
                    borderRadius="5px"
                    fontWeight="bold"
                    textAlign="center"
                    w="full"
                  >
                    Refer a friend
                  </CustomLink>

                  <Button
                    w="full"
                    mt="10px"
                    onClick={handleClose}
                    h="40px"
                    fontWeight={400}
                  >
                    Not right now...
                  </Button>
                </VStack>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};

export default AffiliatePopup;
