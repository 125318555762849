import { DropDown, PrimaryButton, SecondaryButton } from "../design_library";
import useAccountController from "./useAccountController";
import styles from "./Account.module.css";
import {
  Box,
  FormLabel,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaCog } from "react-icons/fa";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../store";
import { Dialog } from "../design_library/Dialog";
import { SettingsIcon } from "@chakra-ui/icons";
import { MdAccountBalance } from "react-icons/md";
import KnowledgeBaseHelper from "../../features/knowledgebase/KnowledgeBaseHelper";
import React from "react";
import { BiMoney, BiMoneyWithdraw, BiTrash } from "react-icons/bi";
import ExportAccount from "./ExportAccount";
const AccountManager = () => {
  return (
    <Box
      backgroundColor={"var(--bg)"}
      borderRadius={"6px"}
      p="2px"
      maxW="275px"
    >
      <HStack
        className={[
          "navbar-hook-select-container",
          "tutorial-account-manager",
        ].join(" ")}
        spacing="0"
      >
        <SelectAccountDropdown />
        <MangageAccounts />
      </HStack>
    </Box>
  );
};

const SelectAccountDropdown = () => {
  const controller = useAccountController();
  const accountState = useAtomValue(AccountAtom);
  return (
    <>
      {accountState?.selectedAccount?.name && (
        <DropDown
          className={[
            // styles["custom-select"],
            "navbar-hook-select",
            ,
          ].join(" ")}
          options={accountState.accounts.map((account) => ({
            value: account,
            label: account.name,
          }))}
          placeholder={accountState.selectedAccount.name}
          onChange={(event: any) => {
            controller.setSelectedAccount(event.value);
          }}
        />
      )}
    </>
  );
};

const MangageAccounts = () => {
  const controller = useAccountController();
  const accountState = useAtomValue(AccountAtom);
  return (
    <>
      <SecondaryButton
        onClick={() => controller.setModalOpen(true)}
        className="navbar-hook-settings-button"
      >
        <MdAccountBalance fontSize={"18px"} />
      </SecondaryButton>

      <Modal
        size={"3xl"}
        isOpen={controller.modalOpen}
        onClose={() => controller.setModalOpen(false)}
      >
        <ModalOverlay backdropFilter="blur(3px)" />

        <ModalContent bg="var(--bg)">
          <ModalHeader>
            <HStack justifyContent={"space-between"} mt="10px">
              <Heading fontSize={"xl"}>Account Management</Heading>
              <ExportAccount />
            </HStack>
            <Text fontSize={"md"} fontWeight={500}>
              Manage your accounts and transactions.
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody bg="var(--bg)">
            <Box mb="20px">
              <form onSubmit={controller.handleSubmit}>
                <FormLabel>Account Name</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={controller.accountForm.name}
                  onChange={controller.handleFormChange}
                  placeholder="Enter account name"
                  required
                />
                <SecondaryButton type="submit" w="full" mt="12px">
                  {controller.editingId ? "Update" : "Create account"}
                </SecondaryButton>
              </form>
            </Box>

            <Heading fontSize={"md"} mb="12px">
              Your Accounts
            </Heading>
            {accountState.accounts.map((account) => (
              <HStack
                key={account.id}
                w="100%"
                justifyContent={"space-between"}
              >
                <HStack
                  mb="10px"
                  // border={"solid 1px var(--gray)"}
                  w="full"
                  p="8px"
                  pl="12px"
                  borderRadius={"6px"}
                  justifyContent={"space-between"}
                  bg="var(--dark-gray)"
                >
                  <Input
                    defaultValue={account.name}
                    onChange={(e) => {
                      controller.updateAccountName(
                        account.id,
                        e.target.value,
                        account
                      );
                    }}
                    placeholder="Enter account name"
                    w="200px"
                  />

                  <HStack>
                    <SecondaryButton
                      size="xs"
                      onClick={() => {
                        controller.setSelectedAccount(account);
                        setTimeout(() => {
                          controller.setModalOpen(false);
                        }, 20);
                      }}
                    >
                      Open account
                    </SecondaryButton>

                    <Dialog
                      buttonSize="xs"
                      title={"Delete all transactions"}
                      buttonText={<Text>Delete trades</Text>}
                      description={`Are you sure you want to delete all transactions for account ${account.name}?`}
                      confirmText={"Confirm"}
                      onConfirm={() =>
                        controller.deleteAllTheTrades(account.id)
                      }
                    />
                    <Dialog
                      buttonSize="xs"
                      title={"Remove Account"}
                      disabled={accountState.accounts.length === 1}
                      buttonText={<Text>Delete Account</Text>}
                      description={`Are you sure you want to delete ${account.name} and all associated transactions?`}
                      confirmText={"Confirm"}
                      onConfirm={() => controller.handleDelete(account.id)}
                    />
                  </HStack>
                </HStack>
              </HStack>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AccountManager;
