import { Button, Box, HStack, useColorMode, Tooltip } from "@chakra-ui/react";
import {
  BiBold,
  BiCodeBlock,
  BiHardHat,
  BiImageAdd,
  BiItalic,
  BiLink,
  BiLinkAlt,
  BiListMinus,
  BiListPlus,
  BiParagraph,
  BiRedo,
  BiSolidFilePlus,
  BiSolidQuoteAltLeft,
  BiStrikethrough,
  BiUndo,
  BiVerticalCenter,
} from "react-icons/bi";
import { TbCodeCircle } from "react-icons/tb";
import { BubbleMenu, FloatingMenu, useCurrentEditor } from "@tiptap/react";
import { FileUploader } from "react-drag-drop-files";
import { useCallback } from "react";
import { uploadImage } from "../../api";
import React from "react";
import { BsYoutube } from "react-icons/bs";

export const MenuBar = () => {
  const { editor } = useCurrentEditor();
  const { colorMode } = useColorMode();
  const [height, setHeight] = React.useState(480);
  const [width, setWidth] = React.useState(640);

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    if (url === null) return;
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  const addYoutubeVideo = () => {
    const url = prompt("Enter YouTube URL");

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
        width: Math.max(320, parseInt(width, 10)) || 640,
        height: Math.max(180, parseInt(height, 10)) || 480,
      });
    }
  };

  if (!editor) return null;

  return (
    <Box p="2" borderRadius="md" shadow="sm">
      {/* {editor && (
        <FloatingMenu
          className="floating-menu"
          tippyOptions={{ duration: 100 }}
          editor={editor}
        ></FloatingMenu>
      )} */}

      <HStack spacing="2">
        <Tooltip label="Bold" aria-label="Bold Tooltip">
          <Button
            p="0"
            onClick={() => editor.chain().focus().toggleBold().run()}
            isDisabled={!editor.can().chain().focus().toggleBold().run()}
            colorScheme={editor.isActive("bold") ? "blue" : "gray"}
          >
            <BiBold fontSize="lg" />
          </Button>
        </Tooltip>
        <Tooltip label="Italic" aria-label="Italic Tooltip">
          <Button
            p="0"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            isDisabled={!editor.can().chain().focus().toggleItalic().run()}
            colorScheme={editor.isActive("italic") ? "blue" : "gray"}
          >
            <BiItalic fontSize="lg" />
          </Button>
        </Tooltip>
        <Tooltip label="Strikethrough" aria-label="Strikethrough Tooltip">
          <Button
            p="0"
            onClick={() => editor.chain().focus().toggleStrike().run()}
            isDisabled={!editor.can().chain().focus().toggleStrike().run()}
            colorScheme={editor.isActive("strike") ? "blue" : "gray"}
          >
            <BiStrikethrough fontSize="lg" />
          </Button>
        </Tooltip>
        <Tooltip label="Code" aria-label="Code Tooltip">
          <Button
            p="0"
            onClick={() => editor.chain().focus().toggleCode().run()}
            isDisabled={!editor.can().chain().focus().toggleCode().run()}
            colorScheme={editor.isActive("code") ? "blue" : "gray"}
          >
            <TbCodeCircle fontSize="lg" />
          </Button>
        </Tooltip>
        <Tooltip label="Paragraph" aria-label="Paragraph Tooltip">
          <Button
            p="0"
            onClick={() => editor.chain().focus().setParagraph().run()}
            colorScheme={editor.isActive("paragraph") ? "blue" : "gray"}
          >
            <BiParagraph fontSize="lg" />
          </Button>
        </Tooltip>
        <Tooltip label="Heading 1" aria-label="Heading 1 Tooltip">
          <Button
            p="0"
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
            colorScheme={
              editor.isActive("heading", { level: 1 }) ? "blue" : "gray"
            }
          >
            H1
          </Button>
        </Tooltip>
        <Tooltip label="Heading 2" aria-label="Heading 2 Tooltip">
          <Button
            p="0"
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            colorScheme={
              editor.isActive("heading", { level: 2 }) ? "blue" : "gray"
            }
          >
            H2
          </Button>
        </Tooltip>
        <Tooltip label="Heading 3" aria-label="Heading 3 Tooltip">
          <Button
            p="0"
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
            colorScheme={
              editor.isActive("heading", { level: 3 }) ? "blue" : "gray"
            }
          >
            H3
          </Button>
        </Tooltip>
        <Tooltip label="Bullet List" aria-label="Bullet List Tooltip">
          <Button
            p="0"
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            colorScheme={editor.isActive("bulletList") ? "blue" : "gray"}
          >
            <BiListMinus fontSize="lg" />
          </Button>
        </Tooltip>
        <Tooltip label="Ordered List" aria-label="Ordered List Tooltip">
          <Button
            p="0"
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            colorScheme={editor.isActive("orderedList") ? "blue" : "gray"}
          >
            <BiListPlus fontSize="lg" />
          </Button>
        </Tooltip>
        {/* <Tooltip label="Code Block" aria-label="Code Block Tooltip">
          <Button
            p="0"
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            colorScheme={editor.isActive("codeBlock") ? "blue" : "gray"}
          >
            <BiCodeBlock fontSize="lg" />
          </Button>
        </Tooltip> */}
        <Tooltip label="Blockquote" aria-label="Blockquote Tooltip">
          <Button
            p="0"
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            colorScheme={editor.isActive("blockquote") ? "blue" : "gray"}
          >
            <BiSolidQuoteAltLeft fontSize="lg" />
          </Button>
        </Tooltip>
        <Tooltip label="Horizontal Rule" aria-label="Horizontal Rule Tooltip">
          <Button
            p="0"
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
            colorScheme="gray"
          >
            <BiVerticalCenter fontSize="lg" />
          </Button>
        </Tooltip>
        <Tooltip label="Hard Break" aria-label="Hard Break Tooltip">
          <Button
            p="0"
            onClick={() => editor.chain().focus().setHardBreak().run()}
            colorScheme="gray"
          >
            <BiHardHat fontSize="lg" />
          </Button>
        </Tooltip>
        <Tooltip label="Undo" aria-label="Undo Tooltip">
          <Button
            p="0"
            onClick={() => editor.chain().focus().undo().run()}
            isDisabled={!editor.can().chain().focus().undo().run()}
            colorScheme="gray"
          >
            <BiUndo fontSize="lg" />
          </Button>
        </Tooltip>
        <Tooltip label="Redo" aria-label="Redo Tooltip">
          <Button
            p="0"
            onClick={() => editor.chain().focus().redo().run()}
            isDisabled={!editor.can().chain().focus().redo().run()}
            colorScheme="gray"
          >
            <BiRedo fontSize="lg" />
          </Button>
        </Tooltip>
        <Tooltip label="Set Link" aria-label="Set Link Tooltip">
          <Button
            p="0"
            onClick={setLink}
            colorScheme={editor.isActive("link") ? "blue" : "gray"}
          >
            <BiLink fontSize="lg" />
          </Button>
        </Tooltip>
        <Tooltip label="Unset Link" aria-label="Unset Link Tooltip">
          <Button
            p="0"
            onClick={() => editor.chain().focus().unsetLink().run()}
            isDisabled={!editor.isActive("link")}
            colorScheme="gray"
          >
            <BiLinkAlt fontSize="lg" />
          </Button>
        </Tooltip>
        <Tooltip
          label="Add Image from URL"
          aria-label="Add Image from URL Tooltip"
        >
          <Button
            p="0"
            onClick={async () => {
              const url = window.prompt("URL");
              if (url) {
                const base64 = await fetch(url)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    return new Promise((res) => {
                      reader.onloadend = () => {
                        res(reader.result);
                      };
                    });
                  });
                editor
                  .chain()
                  .focus()
                  .setImage({ src: `${base64}` })
                  .run();
              }
            }}
            isDisabled={!editor.can().chain().focus().redo().run()}
            colorScheme="gray"
          >
            <BiImageAdd fontSize="lg" />
          </Button>
        </Tooltip>
        <FileUploader
          handleChange={async (file: any) => {
            const response = await uploadImage(file);
            if (response.success) {
              editor
                .chain()
                .focus()
                .setImage({ src: response.directory })
                .run();
            } else {
              alert(`Error uploading image: ${response.message}`);
            }
          }}
          enctype="multipart/form-data"
          name="file"
          disableDragAndDrop={true}
          children={
            <Tooltip label="Upload Image" aria-label="Upload Image Tooltip">
              <Button p="0" colorScheme="gray">
                <BiSolidFilePlus fontSize="lg" />
              </Button>
            </Tooltip>
          }
        />

        <Tooltip
          label="Add video from YouTube"
          aria-label="Add video from YouTube"
        >
          <Button p="0" onClick={addYoutubeVideo} colorScheme="gray">
            <BsYoutube fontSize={"lg"} />
          </Button>
        </Tooltip>
      </HStack>
    </Box>
  );
};
