import React from "react";
import {
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  GridContainer,
  columnTypes,
  distinctValue,
  isMobile,
} from "../../components";
import { useEffect, useState } from "react";
import { LicenseManager } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import "./PositionGrid.css";
import { usePositionController } from "./usePositionController";
import { TradesView } from "./tradesview/TradesView";
import { RowDoubleClickedEvent, RowSelectedEvent } from "ag-grid-community";
import { useGridContextMenuController } from "../../components/grid/utils/useGridContextMenuController";
import { PositionsInnerRender } from "./grid/PositionsInnerRender";
import mixpanel from "mixpanel-browser";
import { useTour } from "@reactour/tour";
import { progressTour } from "../../components/tutorial/util/progressTour";
import { useGridState } from "../../components/grid/useGridState";
import { Toolbar } from "../../components/toolbar/Toolbar";
import { KnowledgeBaseHelperTopics, ToolbarFeatures } from "../../types";
import { FaRegLightbulb } from "react-icons/fa";
import Uploads from "../uploads/Uploads";

LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-042988 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Option Tracker Ltd )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Option Tracker )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Option Tracker )_need_to_be_licensed___( Option Tracker )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 26 June 2024 )____[v2]_MTcxOTM1NjQwMDAwMA==da26b5a51856f363e61b37e5775314c6"
);

const Positions = () => {
  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } =
    useTour();

  const [showTrades, setShowTrades] = useState(false);
  const [tradeIds, setTradeIds] = useState<string[]>([]);
  const [selectedParams, setSelectedParams] = useState<any>({});
  const controller = usePositionController();
  const contextController = useGridContextMenuController();

  const gridState = useGridState({ gridName: "Positions" });

  const determineTradeIds = (rowIds: number[]) => {
    if (rowIds.length > 0) {
      setTradeIds(controller.getTradeIds(rowIds));
    }
  };

  const onRowSelected = (e: RowSelectedEvent) => {
    if (e.node.data && e.node.data.id) {
      determineTradeIds([e.node.data.id]);
    }
  };

  const openPane = (params: RowDoubleClickedEvent, dontOpen: boolean) => {
    if (!params.node.isSelected()) {
      return;
    }

    if (params.node.group) {
      const ids = params.node.allLeafChildren.map((x) => x.data.id);
      determineTradeIds(ids);

      if (!dontOpen) {
        setShowTrades(true);
      }
    } else if (params.node.data && params.node.data.id) {
      determineTradeIds([params.node.data.id]);

      if (!dontOpen) {
        setShowTrades(true);
      }
    }

    setSelectedParams(params);
  };

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      window.location.href = "/app/login";
    }
  }, []);

  return (
    <>
      <Flex h="full" direction="column">
        <Toolbar
          enabledOptions={[
            ToolbarFeatures.UNDERLYING,
            ToolbarFeatures.STATUS,
            ToolbarFeatures.GROUPING,
            ToolbarFeatures.TAGS,
            ToolbarFeatures.POSITION_GROUPING,
            ToolbarFeatures.HELP,
            ToolbarFeatures.PRICING_QUEUE,
            ToolbarFeatures.BOOKS,
            ToolbarFeatures.CLEAR,
            ToolbarFeatures.STRATEGY,
          ]}
          helpKey={KnowledgeBaseHelperTopics.POSITIONS}
          positionsController={controller}
        />

        {controller.positions.length === 0 ? (
          <Center h="70%">
            <VStack>
              <FaRegLightbulb size="50" mb="10px" />
              <Heading size="md">No Positions Found</Heading>
              <Text maxW="300px" textAlign={"center"} mb="20px">
                Please add your trades to see positions.
              </Text>
              <Uploads />
            </VStack>
          </Center>
        ) : (
          <Flex flex={1}>
            <Allotment vertical separator={true}>
              <GridContainer>
                <>
                  {controller.switchingColDef === false && (
                    <>
                      <AgGridReact
                        autoGroupColumnDef={{
                          cellRendererParams: {
                            suppressCount: true,
                            innerRenderer: PositionsInnerRender,
                          },
                          sortable: false,
                          width: 520,
                          resizable: false,
                          headerName: "Book",
                        }}
                        rowData={controller.positions} // Row Data for Rows
                        columnDefs={controller.columnDefs} // Column Defs for Columns
                        columnTypes={columnTypes} // Optional - custom column types
                        // rowSelection="single" // Options - allows click selection of rows
                        rowSelection="multiple" // Options - allows click selection of rows
                        rowHeight={31}
                        showOpenedGroup={true}
                        suppressAggFilteredOnly={false}
                        suppressAggFuncInHeader={true}
                        ref={controller.gridApiRef}
                        sideBar={{
                          toolPanels: [
                            {
                              id: "columns",
                              labelDefault: "Columns",
                              labelKey: "columns",
                              iconKey: "columns",
                              toolPanel: "agColumnsToolPanel",
                              toolPanelParams: {
                                suppressPivotMode: true,
                                suppressValues: true,
                                suppressRowGroups: true,
                              },
                            },
                          ],
                          position: "left",
                          defaultToolPanel: "filters",
                        }}
                        rowDragEntireRow={isMobile}
                        suppressChangeDetection={false}
                        getContextMenuItems={
                          contextController.getContextMenuItems
                        }
                        reactiveCustomComponents={true}
                        aggFuncs={{
                          distinct: distinctValue,
                          none: () => {
                            return "";
                          },
                        }}
                        onRowGroupOpened={(params) => {
                          progressTour(
                            params,
                            setCurrentStep,
                            currentStep,
                            setIsOpen
                          );

                          if (
                            window.location.href.includes("tutorial=true") ||
                            window.location.href.includes("result=paid")
                          ) {
                            if (params.node.level === 2) {
                              openPane(params);
                            }
                          }
                        }}
                        rowDragManaged={false}
                        rowDragMultiRow={true}
                        getRowId={controller.getRowId}
                        onRowSelected={(e: RowSelectedEvent) => {
                          onRowSelected(e);
                          // openPane(e, true);
                        }}
                        onRowDoubleClicked={(params: RowDoubleClickedEvent) => {
                          mixpanel.track("Position Double Clicked");
                          openPane(params, false);
                        }}
                        loadingOverlayComponent={
                          controller.loadingOverlayComponent
                        }
                        loadingOverlayComponentParams={
                          controller.loadingOverlayComponentParams
                        }
                        noRowsOverlayComponent={
                          controller.noRowsOverlayComponent
                        }
                        noRowsOverlayComponentParams={
                          controller.noRowsOverlayComponentParams
                        }
                        onRowDragMove={controller.onRowDragMove}
                        onRowDragEnd={controller.onRowDragEnd}
                        animateRows={false}
                        onGridReady={gridState.onGridReady}
                        onFirstDataRendered={gridState.onFirstDataRendered}
                        onColumnEverythingChanged={gridState.onColumnChanged}
                        onColumnResized={gridState.onColumnResized}
                        onSortChanged={gridState.onColumnChanged}
                      />
                    </>
                  )}
                </>
              </GridContainer>

              <Allotment.Pane
                visible={showTrades}
                preferredSize={400}
                minSize={200}
              >
                <TradesView
                  onClose={() => setShowTrades(false)}
                  params={selectedParams}
                  tradeIds={tradeIds}
                />
              </Allotment.Pane>
            </Allotment>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default Positions;
